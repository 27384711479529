class NavWidthCalc {
	constructor() {
        this.calulate();

        // We listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });
	}
    calulate() {
        const navBottomBtns = document.querySelector('.nav-bottom-btns');
        if (navBottomBtns) {
            let nw = window.innerWidth - navBottomBtns.offsetLeft;

            // Set the value in the --vh custom variable to the root of the document
            document.documentElement.style.setProperty('--nw', `${nw}px`);
        }
    }
}

export default new NavWidthCalc();
