// Imports
import gsap from 'gsap';
import Splide from '@splidejs/splide';

class Home {
	constructor() {
		/*
         * Home Slider
         */
        const homeSlider = document.querySelector('.home-slider');
        const homeSliderPaginationBtns = document.querySelectorAll('.home-slider-pag-btn');

        if (homeSlider) {
            this.homeSplide = new Splide('.home-slider', {
                arrows: false,
                autoplay: true,
                drag: false,
                interval: 6000,
                pagination: false,
                pauseOnFocus: false,
                pauseOnHover: false,
                rewind: true,
                speed: 2000,
                type: 'fade',
            });

            this.homeSplide.on('mounted', () => {
                let firstSlide = homeSlider.querySelectorAll('.slide')[0]
                let slideBg = firstSlide.querySelector('.slide-bg');
                slideBg.classList.add('animate-zoom-in-slow');
            });

            this.homeSplide.mount();

            this.homeSplide.on('move', (newIndex, prevIndex, destIndex) => {
                let activeBtn = document.querySelectorAll('.home-slider-pag-btn.is-active')[0];
                let newBtn = document.querySelectorAll('.home-slider-pag-btn[data-index="' + (destIndex+1) + '"]')[0];

                // Remove active
                activeBtn.classList.remove('is-active');
                activeBtn.removeAttribute('aria-selected');
                activeBtn.setAttribute('tabindex', '-1');

                // New active
                newBtn.classList.add('is-active');
                newBtn.setAttribute('aria-selected', 'true');
                newBtn.removeAttribute('tabindex');
            });

            this.homeSplide.on('active', (slide) => {
                let slideBg = slide.slide.querySelector('.slide-bg');
                slideBg.classList.remove('animate-zoom-out-slow');
                slideBg.classList.add('animate-zoom-in-slow');

                let content = slide.slide.querySelector('.slide-content');
                content.classList.remove('animate-fade-out');
                content.classList.add('animate-fade-in');
            });

            this.homeSplide.on('inactive', (slide) => {
                let slideBg = slide.slide.querySelector('.slide-bg');
                slideBg.classList.remove('animate-zoom-in-slow');
                slideBg.classList.add('animate-zoom-out-slow');

                let content = slide.slide.querySelector('.slide-content');
                content.classList.remove('animate-fade-in');
                content.classList.add('animate-fade-out');
            });

            homeSliderPaginationBtns.forEach((item, i) => {
                let btnIndex = item.getAttribute('data-index');

                item.addEventListener('click', () => {
                    this.homeSplide.go(btnIndex-1);
                });
            });
        }
	}
	leave(){
		// Destroy slider
		this.homeSplide.destroy();
	}
}

export default Home
