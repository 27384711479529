// Imports
import gsap from 'gsap';

// Project imports
import env from '@js/utils/env';
import PageScrolling from '@js/modules/PageScrolling';

const HTML_CLASSLIST = document.documentElement.classList;
const CSS_OPENED = '_nav-open';
const MOBILE_NAV_MAX_WINDOW_WIDTH = 1024;

class Navigation {
    constructor() {
        this.reducedMotion = env.reducedMotion;
        this.opened = false;
        this.animating = false;
        this.logoShowing = window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH ? false : true; // Does not take into account of the logo is showing on the home page or other pages

        this.opener = document.querySelector('.nav-btn');
        this.nav = document.querySelector('.mn-nav');
        this.activeNav = null;
        this.navBtns = null;
        this.mobileNav = document.querySelector('.mobile-nav');

        this.logomark = document.querySelector('.menu-logomark');
        this.logo = document.querySelector('.menu-logo');

        this.hoytLogomark = document.querySelector('.hoyt-logomark');
        this.halLogomark = document.querySelector('.hal-logomark');

        this.hoytLogoText = document.querySelector('.menu-logo-hoyt');
        this.halLogoText = document.querySelector('.menu-logo-hal');

        this.menuPageTitleHeader = document.querySelector('.menu-page._header');
    }

    init() {
        this.menuPage();
        this.calculateAccordionHeight();

        if (this.opener) {
            this.opener.addEventListener('click', this.toggle.bind(this));
        }

        document.body.addEventListener('click', this.handleClickOutside.bind(this));

        this.handleResizeDebounced = this.debounce(this.handleResize.bind(this), 50);
        window.addEventListener('resize', this.handleResizeDebounced);

        document.querySelectorAll('[data-close-nav]').forEach(item => {
            item.addEventListener('click', this.close.bind(this));
        });

        this.logomark.addEventListener('click', this.close.bind(this));
    }

    debounce(func, delay) {
        let timeoutId;

        return function() {
            const context = this;
            const args = arguments;

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    }

    menuPage() {
        const isNotHalEls = document.querySelectorAll('.is-not-hal');
        const isHalEls = document.querySelectorAll('.is-hal');
        const hoytMenu = document.querySelector('[data-menu="hoyt"]');
        const hoytLabMenu = document.querySelector('[data-menu="hoyt-lab"]');
        const activeBtnClass = '_active';
        const activeNavClass = '_active-nav';

        // Reset els
        isNotHalEls.forEach(el => {
           el.classList.remove(activeBtnClass);
        });
        isHalEls.forEach(el => {
           el.classList.remove(activeBtnClass);
        });

        // Add active class based on section
        if (HTML_CLASSLIST.contains('_page-hal')) {
            // Is HAL section

            document.querySelectorAll('.is-hal').forEach(el => {
                el.classList.add(activeBtnClass);
            });

            // Update nav buttons
            hoytMenu.classList.remove(activeNavClass);
            hoytLabMenu.classList.add(activeNavClass);

            // Update active nav var
            this.activeNav = hoytLabMenu;

            // Update mobile nav
            document.querySelector('.mobile-nav-dropdown-hal').click();
        }
        else {
            // Is not HAL section

            document.querySelectorAll('.is-not-hal').forEach(el => {
                el.classList.add(activeBtnClass);
            });

            // Update nav buttons
            hoytLabMenu.classList.remove(activeNavClass);
            hoytMenu.classList.add(activeNavClass);

            // Update active nav var
            this.activeNav = hoytMenu;

            // Update mobile nav
            document.querySelector('.mobile-nav-dropdown-hoyt').click();
        }
    }

    updateLogos() {
        const [hideLogoMark, hideLogo, showLogoMark, showLogo] = HTML_CLASSLIST.contains('_page-hal') ?
            [this.hoytLogomark, this.hoytLogoText, this.halLogomark, this.halLogoText] :
            [this.halLogomark, this.halLogoText, this.hoytLogomark, this.hoytLogoText];

        const animationProps = {
            duration: this.reducedMotion ? 0 : 0.3,
            alpha: 0,
            display: 'none'
        };

        // Hide logo mark and text
        gsap.to([hideLogoMark, hideLogo], animationProps);

        // Show logo mark and text
        if (window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH) {
            gsap.to([showLogoMark], {...animationProps, alpha: 1, display: 'block'});
        }
        else {
            gsap.to([showLogoMark, showLogo], {...animationProps, alpha: 1, display: 'block'});
        }
    }

    updateMenu() {
        this.updateLogos();

        this.menuPage();
    }

    open() {
        if (this.animating) {
            return;
        }

        this.animating = true;
        this.opened = true;

        // Get nav buttons
        this.navBtns = this.activeNav.querySelector('.nav-menu-frame');

        // Update accessibility tags
        this.opener.setAttribute('aria-expanded', true);

        // Add class
        HTML_CLASSLIST.add(CSS_OPENED);

        // Lock scroll
        if (window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH) {
            PageScrolling.lock(this.nav);
        }

        // Show nav
        gsap.to(this.nav, {
            duration: this.reducedMotion ? 0 : this.navAnimateDuration,
            ease: "circ.inOut",
            x: window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH ? 0 : '120px',
            onComplete: () => {
                this.animating = false;
            }
        });

        gsap.to(window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH ? this.mobileNav : this.navBtns, {
            delay: 0.2,
            duration: this.reducedMotion ? 0 : .3,
            alpha: 1,
            display: 'flex',
        });

        // Show logo text
        if (window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH) {
            this.toggleLogoText('show');
        }
    }
    close() {
        if (this.animating) {
            return;
        }

        this.animating = true;

        // Get nav buttons
        this.navBtns = this.activeNav.querySelector('.nav-menu-frame');

        // Update accessibility tags
        this.opener.setAttribute('aria-expanded', false);

        HTML_CLASSLIST.remove(CSS_OPENED);

        // Hide nav
        gsap.to(this.nav, {
            duration: this.reducedMotion ? 0 : this.navAnimateDuration,
            ease: "circ.inOut",
            x: '100%',
            onComplete: () => {
                this.opened = false;
                this.animating = false;

                // Enable scroll
                PageScrolling.unlock(this.nav);
            }
        });

        gsap.to(window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH ? this.mobileNav : this.navBtns, {
            delay: 0.2,
            duration: this.reducedMotion ? 0 : .2,
            alpha: 0,
            display: 'none',
        });

        // Hide logo
        if (window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH) {
            this.toggleLogoText('hide');
        }

    }
    toggle() {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    }

    handleClickOutside(event) {
        if (!this.nav.contains(event.target) && !this.opener.contains(event.target) && this.opened) {
            this.close();
        }
    }

    handleResize() {
        this.close();
        this.calculateAccordionHeight();

        if (window.innerWidth < MOBILE_NAV_MAX_WINDOW_WIDTH) {
            this.toggleLogoText('hide');
            this.toggleHeaderPageTitle('show');
        }
        else {
            this.toggleLogoText('show');
            this.toggleHeaderPageTitle('hide');
        }
    }

    toggleHeaderPageTitle(action) {
        // Show page menu
        gsap.to(this.menuPageTitleHeader, {
            duration: this.reducedMotion ? 0 : .3,
            delay: action === 'show' ? this.reducedMotion ? 0 : .3 : 0,
            alpha: action === 'show' ? 1 : 0,
            display: action === 'show' ? 'block' : 'none',
        });
    }
    toggleLogoText(action) {
        // Do nothing if the logo is already showing or hidden
        if (
            (action === 'show' && this.logoShowing === true)
            || (action === 'hide' && this.logoShowing === false)
        ) {
            return;
        }

        // Show logo block
        gsap.set(this.logo, { alpha: 1, display: 'block', });

        // Show logo text
        gsap.set(HTML_CLASSLIST.contains('_page-hal') ? this.halLogoText : this.hoytLogoText, { alpha: 0, display: 'none', });
        gsap.to(HTML_CLASSLIST.contains('_page-hal') ? this.halLogoText : this.hoytLogoText, {
            duration: this.reducedMotion ? 0 : .3,
            delay: action === 'show' ? this.reducedMotion ? 0 : .4 : 0,
            alpha: action === 'show' ? 1 : 0,
            display: action === 'show' ? 'block' : 'none',
            onComplete: () => {
                this.logoShowing = action === 'show' ? true : false;
            }
        });
    }
    calculateAccordionHeight() {
        if (this.mobileNav) {
            const windowHeight = window.innerHeight;
            const mobileNavStyles = this.mobileNav.currentStyle || window.getComputedStyle(this.mobileNav);
            const mobileNavMarginTop = parseInt(mobileNavStyles.marginTop);
            const buttonHeight = 71; // Set manually as it isn't possible to get this initially due to the nav being hidden

            const mobileNavDDEls = this.mobileNav.querySelectorAll('.mobile-nav-dropdown');
            const accordionBtnsHeight = buttonHeight * mobileNavDDEls.length

            const remainingSpace = windowHeight - mobileNavMarginTop - accordionBtnsHeight;

            const mobileNavDDContentEls = this.mobileNav.querySelectorAll('.mobile-nav-dd-content');
            mobileNavDDContentEls.forEach(el => {
                el.style.height = (remainingSpace) + 'px';
            });
        }
    }
}

export default new Navigation();
