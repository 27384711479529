import Splide from '@splidejs/splide';

const HTML_CLASSLIST = document.documentElement.classList;
const BREAKPOINTS = { desktop: 1024 };

class TeamSlider {
	constructor() {
		this.build();
	}
	build() {
		this.sliderClass = '.team-slider';
		this.sliderEls = document.querySelectorAll(this.sliderClass);
		this.sliders = [];

        if (this.sliderEls) {
			for (let i = 0; i < this.sliderEls.length; i++) {
				let sliderEl = this.sliderEls[i],
					sliderSlideCount = sliderEl.getAttribute('data-slides');

				this.sliderOptions = {
					arrows: sliderSlideCount > 2 ? true : false,
					arrowPath: 'm10.45 1 19.1 19.1L10.66 39',
					drag: true,
					padding: { left: '136px', right: '250px' },
					pagination: false,
					perMove: 2,
					perPage: 2,
					speed: 600,
					type: 'slide',
					breakpoints: {
						1150: {
							padding: { left: '136px', right: '150px' },
						},
						1023: {
							padding: 0,
							type: 'loop',
						},
						767: {
							arrows: sliderSlideCount > 1 ? true : false,
							padding: 0,
							perMove: 1,
							perPage: 1,
							type: 'loop',
						}
					}
				};

			  	let slider = new Splide(sliderEl, this.sliderOptions).mount();

				// Add slider to array
				this.sliders.push(slider);
			}
        }
	}
	leave(){
		// Destroy slider
		if (this.sliders) {
			for (let i = 0; i < this.sliders.length; i++) {
			  	this.sliders[i].destroy();
			}
		}

		// Clear array
		this.sliders = [];
	}
}

export default new TeamSlider();
