/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

// Imports
import barba from '@barba/core';
import gsap from 'gsap';

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project classss
import Signal from '@js/classes/Signal';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import NavWidthCalc from '@js/helpers/NavWidthCalc';
import VhCalc from '@js/helpers/VhCalc';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import ExternalLinks from '@js/modules/ExternalLinks';
import HeroStickyScroll from '@js/modules/HeroStickyScroll';
import Navigation from '@js/modules/Navigation';
import Page from '@js/modules/Page';
import ProgressLine from '@js/modules/ProgressLine';
import ScrollTo from '@js/modules/ScrollTo';
import WorkCats from '@js/modules/WorkCats';

// Project pages
import Contact from '@js/pages/Contact';
import Home from '@js/pages/Home';

// HTML elememt classlist
const HTML_CLASSLIST = document.documentElement.classList;
const PAGE_TRANSITION_SPEED = 0.7;

window.HOYT =
    window.HOYT ||
    new (function HOYT() {
        // ENV
        this.env = env;

        // Utilities
        this.utils = utils;

        // Classes
        this.classes = {
            Signal: Signal,
        };

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            ModalOpenClass: '_modal-open',
            VhCalc: VhCalc,
            ScrollYPos: 0,
            GoToScrollPos: false,
            pageTrigger: null,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            ExternalLinks: ExternalLinks,
            HeroStickyScroll: HeroStickyScroll,
            Page: Page,
            ProgressLine: ProgressLine,
            ScrollTo: ScrollTo,
            WorkCats: WorkCats,
        };

        // Pages
        this.pages = {
            contact: Contact,
            home: Home,
        };


        let currentController;
        let currentClasses;
        let currentNameSpace;

        const beforeController = (data, done) => {
            console.log('beforeController');
            done();
        };

        const afterLeaveController = (data, done) => {
            console.log('afterLeaveController');

            const nextNamespace = data.next.namespace;

            if (nextNamespace === 'workentry') {
                window.HOYT.helpers.ScrollYPos = window.scrollY;
                console.log('--------');
                console.log(data.next.namespace);
                console.log('Scroll Y: ',window.HOYT.helpers.ScrollYPos);
                console.log('--------');
            }
            else if (nextNamespace !== 'work') {
                    window.HOYT.helpers.ScrollYPos = 0;
            }

            done();
        };

        const leaveController = (container, done) => {
            HTML_CLASSLIST.add('_page-loading');
            HTML_CLASSLIST.remove('_page-loaded');

            // Stop scroll
            this.modules.PageScrolling.lock(document.body);

            // Show progress line
            this.modules.ProgressLine.show();

            // Fade out container
            gsap.to(container, {
                duration: PAGE_TRANSITION_SPEED,
                alpha: 0
            });

            // Leave the current page if exists
            currentController &&
            currentController.leave &&
            currentController.leave();

            done();
        };

        const enterController = (container, firstCall = false) => {
            let namespace = container.getAttribute('data-spa-namespace');

            // If first call
            if (firstCall) {
                Navigation.init();
            }
            else {
                // Page title
                const pageSection = container.getAttribute('data-page-section');
                document.querySelectorAll('.menu-page').forEach(el => {
                    el.innerHTML = pageSection;
                });
            }

            console.log('Trigger: ', window.HOYT.helpers.pageTrigger);
            if (namespace === 'work' && window.HOYT.helpers.pageTrigger === 'back') {
                // Scroll to last known position
                console.log('IS WORK INDEX');
                console.log('ScrollYPos: ', window.HOYT.helpers.ScrollYPos);

                window.HOYT.helpers.GoToScrollPos = true;
            }
            else {
                // Scroll to top
                console.log('NOT WORK INDEX');
                console.log(window.HOYT.helpers.ScrollYPos);
                window.scrollTo(0, 0);
            }

            // Enable scrolling
            this.modules.PageScrolling.unlock(document.body);

            // Modules
            this.modules.ProgressLine.hide();

            if (!this.env.reducedMotion) {
                this.modules.Appear.start();
                this.modules.AppearParent.start();
            }

            // Reinit page functionality
            //this.modules.Page.init();

            this.modules.PageScrolling.start();
            this.modules.PageScrollingManager.update(true);

            // Page controller
            const nameSpace = container.getAttribute('data-spa-namespace');
            const pageController = this.pages[nameSpace.toLowerCase()];
            if (pageController) {
                // Run page JS
                currentController = new pageController(container);
            }
            else {
                currentController = null;
            }

            // Test for old classes - if any, remove them
            if (currentClasses) {
                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.remove(cssClass);
                })
            }
            // Get new classes - if any, add them
            currentClasses = (container.getAttribute('data-spa-class') || null);
            if (currentClasses) {
                currentClasses = currentClasses.split(' ');

                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.add(cssClass);
                });
            }

            currentNameSpace && HTML_CLASSLIST.remove(currentNameSpace);
            currentNameSpace = '_page-' + nameSpace;
            HTML_CLASSLIST.add(currentNameSpace);

            // Page class status
            HTML_CLASSLIST.remove('_page-loading');
            HTML_CLASSLIST.add('_page-loaded');

            // Update active links on page
            Array.from(document.querySelectorAll('a:not(.exclude-active)')).forEach((link) => {
                if (
                    this.utils.testHrefIsActual(link.getAttribute('href'))
                    || window.location.href.includes(link.href)
                ) {
                    link.classList.add('_active');
                }
                else {
                    link.classList.remove('_active');
                }
            });

            Page.init();

            // Mark external links
            DelayedCall.create(1000, () => {
                this.modules.ExternalLinks.build();
            });

            gsap.from(container, {
                duration: PAGE_TRANSITION_SPEED,
                alpha: 0
            });
        };

        const afterController = (next) => {
            // Update menu if popsate
            if (HTML_CLASSLIST.contains('_popstate')) {
                // Remove class
                HTML_CLASSLIST.remove('_popstate');
            }

            WorkCats.build();

            if (window.HOYT.helpers.GoToScrollPos) {
                console.log('APPLY SCROLL');
                console.log(window.HOYT.helpers.ScrollYPos)
                window.scrollTo(0, window.HOYT.helpers.ScrollYPos);

                window.HOYT.helpers.GoToScrollPos = false;
            }
        };

        const start = () => {
            HTML_CLASSLIST.remove('_page-loading');

            enterController(document.body.querySelector('[data-spa-namespace]'), true);

            HTML_CLASSLIST.add('_page-loaded');

            barba.init({
                schema: {
                    prefix: 'data-spa',
                },
                sync: true,
                debug: false,
                prefetchIgnore: true,
                cacheIgnore: true,
                preventRunning: true,
                requestError: (trigger, action, url, response) => {
                    // go to a custom 404 page if the user click on a link that return a 404 response status
                    if (action === 'click' && response.status && response.status === 404) {
                        barba.go('/404');
                    }

                    // Prevent Barba from redirecting the user to the requested URL
                    // this is equivalent to e.preventDefault() in this context
                    return false;
                },
                prevent: ({ el, event }) => {
                    event.stopPropagation();

                    console.log(el.getAttribute('data-external'));

                    if (el.getAttribute('data-external') != null || el.getAttribute('target') === '_blank') {
                        console.log('External link');
                        return true;
                    }

                    console.log('Internal link');

                    event.preventDefault();
                    return false;
                },
                timeout: 60 * 1000,
                transitions: [{
                    async before(data) {
                        console.log('BEFORE');
                        const done = this.async();

                        beforeController(data, done);
                    },
                    async leave(data) {
                        console.log('LEAVE');
                        const done = this.async();

                        console.log('data');
                        console.log(data);
                        window.HOYT.helpers.pageTrigger = data.trigger;
                        leaveController(data.current.container, done);
                    },
                    async afterLeave(data) {
                        console.log('AFTERLEAVE');
                        const done = this.async();

                        afterLeaveController(data, done);
                    },
                    async enter({ current, next }) {
                        console.log('ENTER');

                        enterController(next.container);
                    },
                    async after({ next }) {
                        console.log('AFTER');

                        afterController();

                        // GA page view tracking
                        if (typeof gtag === 'function') {
                            gtag('event', 'page_view', {
                                'page_title': document.title,
                                'page_location': location.href,
                                'page_path': location.pathname,
                            });
                        }
                    }
                }],
                views: [
                    {
                        namespace: 'workentry',
                        afterEnter(data) {
                            window.HOYT.modules.HeroStickyScroll.build();

                            window.HOYT.helpers.DelayedCall.create(1000, () => {
                                window.HOYT.modules.HeroStickyScroll.build();
                            });
                        }
                    }
                ]
            });
        };


        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            // Start SPA
            start();

            // Show mobile nav element - To stop nav flashing at start
            document.querySelector('.mn-nav').classList.remove('hidden');
        });

        // Window Load
        window.addEventListener('load', e => {
            this.modules.PageScrollingManager.update(true);
        });

        // Popstate
        window.addEventListener('popstate', (event) => {
        	// Log the state data to the console
            HTML_CLASSLIST.add('_popstate');
        });

    })();
