// Imports
import MicroModal from 'micromodal';

// Project modules
import env from '@js/utils/env';
import PageScrolling from "@js/modules/PageScrolling";

class Modals {
    constructor() {

    }
    init() {
        MicroModal.init({
            onShow: (modal, trigger, event) => {
                let trig = env.isSafari ? event.target : trigger,
                    type = trig.getAttribute('data-modal-type'),
                    content = null;

                // Lock scroll
                PageScrolling.lock(modal);

                // Add open class to HTML element
                document.documentElement.classList.add(window.HOYT.helpers.ModalOpenClass);

                if (type === 'tour') {
                    modal.classList.add('_full', '_light');

                    let url = trig.getAttribute('data-url');

                    content = '<iframe class="h-full w-full" allow="autoplay; gyroscope; accelerometer" src="' + url + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
                }

                // Modal content
                modal.querySelector('.modal-content').innerHTML = content;
            },
            onClose: (modal) => {
                // Remove full class from modal
                modal.classList.remove('_full', '_light');

                // Clear modal content
                modal.querySelector('.modal-content').innerHTML = '';

                // Unlock scroll
                PageScrolling.unlock(modal);

                // Remove open class to HTML element
                document.documentElement.classList.remove(window.HOYT.helpers.ModalOpenClass);
            },
            openTrigger: 'data-modal-open',
            closeTrigger: 'data-modal-close',
        });
    }
}

export default new Modals();
