const BREAKPOINT_DESKTOP = 1024;

class HeroStickyScroll {
    constructor() {

    }
    build() {
        console.log('Build HeroStickyScroll');
        this.heroContent = document.getElementById('hero-sticky-scroll-el');
        this.endContent = document.getElementById('hero-sticky-scroll-end');
        this.topOffsetPos = 20;

        if (this.heroContent && this.endContent) {
            this.heroContentParent = this.heroContent.parentElement;

            this._init();

            window.addEventListener('scroll', () => {
                this.fixedScroll();
            });

            window.addEventListener('resize', () => {
                this._reinit();
            });
        }
    }
    _init() {
        this.elHeight = this.heroContent.offsetHeight;
        this.initTopPos = this.heroContent.offsetTop;
        this.endTopPos = this.endContent.offsetTop;
        this.heroContentParentPb = parseInt(window.getComputedStyle(this.heroContentParent, null).getPropertyValue('padding-bottom').replace('px', ''));

        this.fixedScroll();
    }
    _reinit() {
        this.elHeight = this.heroContent.offsetHeight;
        this.endTopPos = this.endContent.offsetTop;
        this.heroContentParentPb = parseInt(window.getComputedStyle(this.heroContentParent, null).getPropertyValue('padding-bottom').replace('px', ''));

        this.fixedScroll();
    }
    fixedScroll() {
        const scrollTopPos = window.pageYOffset || document.documentElement.scrollTop;

        if ((scrollTopPos >= 0) && (scrollTopPos < (this.endTopPos - window.innerHeight + this.heroContentParentPb))) {
            // Fixed position
            let bottomPos = window.innerWidth >= BREAKPOINT_DESKTOP ? '31px' : '40px';

            this.heroContent.setAttribute('style', 'position: fixed; bottom:' + bottomPos  + ';');
        }
        else if (scrollTopPos >= (this.endTopPos - window.innerHeight + this.heroContentParentPb)) {
            // Absolute position
            this.heroContent.setAttribute('style', 'position: absolute; bottom: auto; top:' + (this.endTopPos - this.elHeight) + 'px;');
        }
        else {
            // Remove styles
            this.heroContent.removeAttribute('style');
        }
	}
}

export default new HeroStickyScroll();
