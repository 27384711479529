// Imports
import LazyLoad from 'vanilla-lazyload';
import reframe from 'reframe.js';
import ImageCompare from "image-compare-viewer";
import Splide from '@splidejs/splide';

// Modules
import ExternalLinks from "@js/modules/ExternalLinks";
import Hotspot from "@js/modules/Hotspot";
import Modals from "@js/modules/Modals";
import Navigation from "@js/modules/Navigation";
import ScrollTo from "@js/modules/ScrollTo";
import TeamSlider from "@js/modules/TeamSlider";

class Page {
    constructor() {}
    init() {
        // External links
        ExternalLinks.build();

        Hotspot.build();
        ScrollTo.build();
        TeamSlider.build();
        Modals.init();

        /**
         * Open external links in new tab
         */
        // const extLinks = document.querySelectorAll('[data-external]');
        // console.log(extLinks);
        // if (extLinks) {
        //     extLinks.forEach((item, i) => {
        //         item.addEventListener('click', (e) => {
        //             let url = item.getAttribute('href');
        //             window.open(url, '_blank').focus();
        //         });
        //     });
        // }

        /**
         * Lazy load
         */
        let lazyLoadInstance = new LazyLoad({
            threshold: 400
        });

        /**
         * Reframe
         */
        reframe('.reframe');

        /**
         * Navigation
         */
        Navigation.updateMenu();

        /**
         * Image comparison
         */
        const imageCompareEls = document.querySelectorAll('.image-compare');
        imageCompareEls.forEach((element) => {
            let view = new ImageCompare(element).mount();
        });


        /**
         * Sliders
         */
        const sliderEls = document.querySelectorAll('.slider');
        sliderEls.forEach((item, i) => {
            new Splide(item, {
                arrows: true,
                arrowPath: 'm10.45 1 19.1 19.1L10.66 39',
                drag: true,
                pagination: false,
                rewind: true,
                type: 'fade',
            }).mount();
        });
    }
}

export default new Page();
