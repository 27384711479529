// Imports
import gsap from 'gsap';

// Project imports
import env from '@js/utils/env';
import PageScrolling from '@js/modules/PageScrolling';

const HTML_CLASSLIST = document.documentElement.classList;
const CSS_OPENED = '_work-cats-open';
const MOBILE_NAV_MAX_WINDOW_WIDTH = 1024;

class WorkCats {
    constructor() {
        this.build();
    }
    build() {
        this.opened = false;
        this.animating = false;
        this.reducedMotion = env.reducedMotion;
        this.animateDuration = 0.2;

        this.opener = document.querySelector('.work-cats-mobile-toggle');
        this.nav = document.querySelector('.work-cats-mobile-nav');
        this.buttons = document.querySelectorAll('.work-cats-mobile-btn');

        // Mobile nav button
        if (this.opener) {
            this.opener.addEventListener('click', (e) => {
                e.preventDefault();

                if (this.animating) {
                    return;
                }

                this.toggle();
            });
        }

        // Keydown event
        window.addEventListener('keydown', (e) => {
            // ESC key
            if (e.keyCode == 27 && this.opened) {
                this.close();
            }
        });

        // Window resize
        window.addEventListener('resize', (e) => {
            if (window.innerWidth >= MOBILE_NAV_MAX_WINDOW_WIDTH && this.opened) {
                this.close();
            }
        });

        // Buttons
        this.buttons.forEach((item) => {
            // Button click
            item.addEventListener('click', () => {
                // Close menu
                this.close();
            })
        });
    }
    open() {
        if (this.animating) {
            return;
        }

        this.animating = true;
        this.opened = true;

        // Update accessability tags
        this.opener.setAttribute('aria-expanded', true);

        // Add class
        HTML_CLASSLIST.add(CSS_OPENED);

        // Lock scroll
        PageScrolling.lock(this.nav);

        // Show nav
        gsap.to(this.nav, {
            duration: this.reducedMotion ? 0 : this.animateDuration,
            ease: "circ.inOut",
            alpha: 1,
            display: 'block',
            onComplete: () => {
                // Show buttons
                gsap.to(this.buttons, {
                    duration: this.reducedMotion ? 0 : .4,
                    stagger: .1,
                    alpha: 1,
                    y: 0,
                    onComplete: () => {
                        this.animating = false;
                    }
                });
            }
        });
    }
    close() {
        if (this.animating) {
            return;
        }

        this.animating = true;

        // Update accessability tags
        this.opener.setAttribute('aria-expanded', false);

        HTML_CLASSLIST.remove(CSS_OPENED);

        // Hide nav
        gsap.to(this.nav, {
            duration: this.reducedMotion ? 0 : this.animateDuration,
            ease: "circ.inOut",
            alpha: 0,
            onComplete: () => {
                this.opened = false;
                this.animating = false;

                // Unlock scroll
                PageScrolling.unlock(this.nav);

                // Hide buttons
                gsap.to(this.buttons, {
                    duration: 0,
                    alpha: 0,
                    y: '30px'
                });
            }
        });


    }
    toggle() {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    }
}

export default new WorkCats();
