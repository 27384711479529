import env from '@js/utils/env';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import DelayedCall from '@js/helpers/DelayedCall';

class Appear {
    constructor() {
        this.appearTargets = [];
    }
    start() {
        this._setup();
    }
    clear() {
        this.appearTargets.forEach((target) => {
			PageScrollingManager.unwatch(target);
		});
    }
    _setup() {
        const attrName = 'data-appear';

        // Appear elements
		const appearElements = document.querySelectorAll('[' + attrName + ']');

        // Callback for IntersectionObserver
        const appearCallback = function(entries) {
            entries.forEach(entry => {
                const attributeValues = entry.target
    				.getAttribute(attrName)
    				.split(',');

                // Get the default delay duration
                const delay = Number.parseFloat(attributeValues[0] || 0);
                // Get the mobile delay duration
                const delayMobile = Number.parseFloat(attributeValues[1] || 0);
                // Get the class to add to the element
    			const addClass = attributeValues[2] || '_visible';

                // Is the element in the viewport?
                if (entry.isIntersecting) {
                    let delayedCallDelay = delay;

                    // If mobile value has been passed and it is a mobile device get mobile delay
                    if (delayMobile > 0 && (env.isMobile || window.innerWidth < 768)) {
                        delayedCallDelay = delayMobile;
                    }

                    DelayedCall.create(delayedCallDelay, () => {
						entry.target.classList.add(addClass);

                        appearObserver.unobserve(entry.target);
					});
                }
            });
        };

        const appearObserver = new IntersectionObserver(appearCallback, {
            root: null,
            threshold: 0.1
        });
        const appearObserverFull = new IntersectionObserver(appearCallback, {
            root: null,
            threshold: 1
        });
		appearElements.forEach((target) => {
            // Add the element to the watcher
            if (target.hasAttribute('data-countup')) {
                appearObserverFull.observe(target);
            }
            else {
                appearObserver.observe(target);
            }
		});
    }
}

export default new Appear();
