// Imports
import gsap from 'gsap';

class Hotspot {
    constructor() {

    }
    build() {
        const hotspots = document.querySelectorAll('.hotspot');

        hotspots.forEach((item, i) => {
            this._init(item);
        });
    }
    _init(hotspot) {
        const NAV_OPEN_CLASS = '_nav-open';

        const nav = hotspot.querySelector('.hotspot-nav');
        const navBtnsContainer = hotspot.querySelector('.hotspot-nav-btns');
        const navBtns = hotspot.querySelectorAll('.hotspot-nav-btn');
        const navToggle = hotspot.querySelector('.hotspot-nav-toggle');
        const pins = hotspot.querySelectorAll('.pin');
        const closeContentBtns = hotspot.querySelectorAll('.hotspot-content-close');
        let currentId = null;

        // Toggle button
        if (navToggle) {
            navToggle.addEventListener('click', () => {
                toggleNav();
            });
        }

        // Pins
        if (pins.length) {
            pins.forEach((item, i) => {
                pinClick(item);
            });
        }

        // Nav buttons
        if (navBtns.length) {
            navBtns.forEach((item, i) => {
                pinClick(item);
            });
        }

        closeContentBtns.forEach((item) => {
            item.addEventListener('click', () => {
                closeContent();
            });
        });


        function toggleNav() {
            if (hotspot.classList.contains(NAV_OPEN_CLASS)) {
                closeNav();
            }
            else {
                openNav();
            }
        }
        function openNav() {
            hotspot.classList.add(NAV_OPEN_CLASS);

            gsap.to(
                navBtnsContainer,
                {
                    duration: .3,
                    display: 'block',
                    alpha: 1,
                    onComplete: () => {
                        gsap.to(
                            navBtns,
                            {
                                duration: .35,
                                x: 0,
                                alpha: 1,
                                stagger: .08
                            }
                        );
                    }
                }
            );
        }
        function closeNav() {
            hotspot.classList.remove(NAV_OPEN_CLASS);

            gsap.to(
                navBtnsContainer,
                {
                    duration: .2,
                    alpha: 0,
                    onComplete: () => {
                        gsap.to(
                            navBtnsContainer,
                            {
                                duration: 0,
                                display: 'none',
                            }
                        );

                        gsap.to(
                            navBtns,
                            {
                                duration: 0,
                                x: '20px',
                                alpha: 0,
                            }
                        );
                    }
                }
            );
        }
        function pinClick(item) {
            let id = item.getAttribute('data-hotspot-id');

            item.addEventListener('click', () => {
                showContent(id);
            });
        }
        function closeContent() {
            let currentContent = hotspot.querySelector('.hotspot-content._active'),
                currentPin = hotspot.querySelector('.pin._active');

            // Remove current content
            if (currentContent) {
                let currentContentLines = currentContent.querySelectorAll('.content-item');

                gsap.to(
                    currentContent,
                    {
                        duration: .4,
                        display: 'none',
                        alpha: 0,
                        onComplete: () => {
                            currentContent.classList.remove('_active');

                            currentId = null;

                            gsap.to(
                                currentContent,
                                {
                                    duration: 0,
                                    display: 'none',
                                }
                            );

                            gsap.to(
                                currentContentLines,
                                {
                                    duration: 0,
                                    y: '20px',
                                    alpha: 0,
                                }
                            );
                        }
                    }
                );
            }

            if (currentPin) {
                currentPin.classList.remove('_active');
            }
        }
        function showContent(id) {
            if (id == currentId) {
                return;
            }

            let currentContent = hotspot.querySelector('.hotspot-content._active'),
                content = hotspot.querySelector('[data-hotspot-content="'+id+'"]'),
                contentLines = content.querySelectorAll('.content-item'),
                pin = hotspot.querySelector('.pin[data-hotspot-id="'+id+'"]');

            // Close nav
            if (window.innerWidth < 768) {
                closeNav();
            }

            // Close content
            closeContent();

            currentId = id;

            // Active pin
            pin.classList.add('_active');

            // Show new content
            gsap.to(
                content,
                {
                    duration: .4,
                    delay: currentContent ? .2 : 0,
                    display: 'block',
                    alpha: 1,
                    onComplete: () => {
                        content.classList.add('_active');

                        gsap.to(
                            contentLines,
                            {
                                duration: .3,
                                y: 0,
                                alpha: 1,
                                stagger: 0.1
                            }
                        );
                    }
                }
            );
        }
    }
}

export default new Hotspot();
